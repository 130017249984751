import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import { BsFillGeoAltFill, BsFillCalendarCheckFill } from "react-icons/bs";
import { useParams, useNavigate } from "react-router-dom";
import { Configuration } from "../../Config";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import BookTickets from "../../BookTickets";
import ChooseTicketType from "../../ChooseTicket/ChooseTicketType";
import Checkout from "../../Checkout";
import swal from "sweetalert";
import GalleryImageComponent from "../../components/gallery-image";
import { getEventDates } from "../../utils/DateUtils";
import _ from "lodash";

function Homepage({ customEventId }) {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [selectedTicket, setSelectedTicket] = useState({});
  const [showBookTicket, setShowBookTicket] = useState(false);
  const [open, setopen] = useState(true);

  const [flag, setflag] = useState(true);

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    setShowBookTicket(false);
    setSelectedTicket({});
    callGetEventDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    if (selectedTicket && !isEmpty(selectedTicket) && !showBookTicket) {
      console.log("selected_ticket", selectedTicket);
      setShowBookTicket(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTicket]);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = `${Configuration.PAYTM_HOST}merchantpgpui/checkoutjs/merchants/${Configuration.PAYTM_MID}.js`;
    script.type = "application/javascript";
    script.crossOrigin = "anonymous";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [loading, setLoader] = useState(false);
  const [event, setEvent] = useState({});

  const [tickets, setTickets] = useState([]);

  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [checkoutSheetOpen, setCheckoutSheetOpen] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({});

  useEffect(() => {
    var date = new Date();
    if (event && date >= new Date(event.end_date)) {
      navigate("/EventNotAvailable");
    }
  }, [event, navigate]);

  const onTicketInfoSubmit = (ticketData) => {
    setTicketDetails(ticketData);
    setBottomSheetOpen(false);
    setCheckoutSheetOpen(true);
  };

  /**
   * get event details
   */
  async function callGetEventDetails() {
    //set loader
    setLoader(true);
    fetch(
      `${Configuration.BASE_URL}event/detail/${
        customEventId ? customEventId : eventId
      }`
    )
      .then((res) => res.json())
      .then((response) => {
        if (!response.error) {
          setEvent(response.data);
          if (response.data && !_.isEmpty(response.data.ticket)) {
            setTickets(
              response.data.ticket.filter(
                (ticket) => ticket.booking_closed === false
              )
            );
          }
          setLoader(false);
        }
      });
  }

  const proceedPaytmPayment = (paymentData) => {
    setCheckoutSheetOpen(false);

    var config = {
      root: "",
      style: {
        bodyBackgroundColor: "#fafafb",
        bodyColor: "",
        themeBackgroundColor: "#0FB8C9",
        themeColor: "#ffffff",
        headerBackgroundColor: "#284055",
        headerColor: "#ffffff",
        errorColor: "",
        successColor: "",
        card: {
          padding: "",
          backgroundColor: "",
        },
      },
      data: {
        orderId: paymentData.order_id,
        token: paymentData.txnToken,
        tokenType: "TXN_TOKEN",
        amount: paymentData.amount /* update amount */,
      },
      payMode: {
        labels: {},
        filter: {
          exclude: [],
        },
        order: ["CARD", "NB", "UPI"],
      },
      flow: "DEFAULT",
      merchant: {
        mid: paymentData.mid,
        redirect: false,
      },
      handler: {
        transactionStatus: function transactionStatus(paymentStatus) {
          console.log("paymentStatus => ", paymentStatus);
          window.Paytm.CheckoutJS.close();
          if (paymentStatus.STATUS === "TXN_SUCCESS") {
            verifyPayment(paymentData.ping_url);
          } else {
            //show transaction failed
            swal("Oops", "Payment Failed!", "error");
          }
        },
        notifyMerchant: function notifyMerchant(eventName, data) {
          console.log("notify => ", data);
        },
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      // initialze configuration using init method
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          console.log("Before JS Checkout invoke");
          // after successfully update configuration invoke checkoutjs
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error) {
          console.log("Error => ", error);
        });
    }
  };

  async function verifyPayment(pingUrl) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("auth-token"),
      },
    };

    fetch(pingUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          console.log("verifyPayment", data);
          if (data && data.data) {
            navigate(`/Thankyoupage/${event.id}`);
            window.location.reload(false);
          }
        } else {
          setTimeout(() => {
            verifyPayment(pingUrl);
          }, 1000);
        }
      });
  }

  function NewlineText(props) {
    const text = props.text;
    return text.split("\n").map((str) => <p>{str}</p>);
  }

  function getDetails() {
    const details = event.description
      .split("\n")
      .filter((value) => value !== "\r");
    return details.slice(0, 3);
  }

  const handleClickFuntion = () => {
    setflag(false);
  };

  const HomePageComponent = () => {
    return (
      <>
        <Header />
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <div style={{ backgroundColor: "black" }}>
            <img
              src={event.cover_image}
              className="coverimage"
              alt="Responsive_image"
            />
            <BottomSheet
              className={`${flag ? "add-class-for-height" : ""}`}
              open={open}
              blocking
              onDismiss={handleClickFuntion}
              onClick={handleClickFuntion}
            >
              <div
                className={`${flag ? "add-class-for-height" : ""}`}
                style={{ marginBottom: "70px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "14px",
                  }}
                >
                  <div style={{ display: "flex", maxWidth: "70%" }}>
                    <h3 style={{ fontWeight: "bold", fontSize: "24px" }}>
                      <b style={{ fontFamily: "Barlow" }}>
                        {event.name?.toUpperCase()} <br />
                      </b>
                    </h3>
                  </div>

                  <div>
                    <img
                      src={event.thumbnail_image}
                      alt="thumbnailimage"
                      style={{
                        borderRadius: "6px",
                        maxWidth: "55px",
                        marginLeft: "25px",
                      }}
                    ></img>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "14px",
                  }}
                >
                  <div style={{ fontSize: "13px" }}>
                    <p className="flex flex-row items-center gap-2">
                      <BsFillCalendarCheckFill />{" "}
                      {getEventDates(event.date, event.end_date)}
                    </p>
                  </div>

                  <div style={{ fontSize: "13px" }}>
                    <p className="flex flex-row items-center gap-2 py-2">
                      <BsFillGeoAltFill fontSize={20} /> {event.location}
                    </p>
                  </div>
                </div>

                {event.gallary_image && event.gallary_image.length > 0 && (
                  <div>
                    <div
                      style={{
                        marginLeft: "22px",
                      }}
                    >
                      <strong>Gallery</strong>
                    </div>

                    <div
                      style={{
                        marginLeft: "20px",
                        marginTop: "8px",
                      }}
                    >
                      <GalleryImageComponent images={event.gallary_image} />
                    </div>
                  </div>
                )}

                <div
                  // onClick={() => setShowTerms(!showTerms)}
                  style={{
                    display: "flex",
                    marginLeft: "14px",
                    marginTop: "12px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginRight: "4px",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <strong>Event Details :</strong>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginLeft: "14px",
                    marginRight: "14px",
                    marginTop: "12px",
                    alignItems: "center",
                    marginBottom: "40px",
                  }}
                >
                  <div className="event-description">
                    <NewlineText
                      text={event.description ? event.description : ""}
                    />
                  </div>
                </div>
                {/* )} */}

                <div
                  className="card"
                  style={{
                    display: "flex",
                    backgroundColor: "#EDEDED",
                    marginLeft: "14px",
                    marginRight: "14px",
                    padding: "12px",
                    borderRadius: "12px",
                  }}
                >
                  <div>
                    <h2 style={{ fontSize: "18px", fontWeight: "800" }}>
                      <b>Knocksense Member Benefits</b>
                    </h2>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      onClick={() => setActiveTab(1)}
                      style={{
                        color: activeTab === 1 ? "orange" : "black",
                        fontSize: "14px",
                        fontWeight: "800",
                      }}
                    >
                      <h6>
                        <b>SELECT</b>
                      </h6>
                    </div>
                    <div
                      onClick={() => setActiveTab(2)}
                      style={{ color: activeTab === 2 ? "orange" : "black" }}
                    >
                      <h6>
                        <b>SELECT MINI</b>
                      </h6>
                    </div>
                    <div
                      onClick={() => setActiveTab(3)}
                      style={{ color: activeTab === 3 ? "orange" : "black" }}
                    >
                      <h6>
                        <b>PLATINUM</b>
                      </h6>
                    </div>
                  </div>

                  <hr
                    className="bg-danger border-2 border-top border-dark"
                    style={{ marginTop: "5px" }}
                  ></hr>

                  <div>
                    <h6>
                      {activeTab === 1 && (
                        <div>
                          <p style={{ marginBottom: "2%" }}>
                            For existing Knocksense Select Members, tickets will
                            be available -
                          </p>
                          {_.isEmpty(tickets) ? (
                            <></>
                          ) : (
                            <>
                              {tickets[0]?.ticket.map((ticketObj) => (
                                <div>
                                  <li
                                    style={{
                                      marginLeft: "10%",
                                      marginBottom: "2%",
                                    }}
                                  >
                                    {ticketObj.name}
                                    {"-"}Rs.
                                    {ticketObj.price -
                                      ticketObj.select_discount}
                                  </li>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      )}
                      {activeTab === 2 && (
                        <div>
                          <p style={{ marginBottom: "2%" }}>
                            For existing Knocksense Select Mini Members, tickets
                            will be available -
                          </p>

                          {_.isEmpty(tickets) ? (
                            <></>
                          ) : (
                            <>
                              {tickets[0]?.ticket.map((ticketObj) => (
                                <div>
                                  <li
                                    style={{
                                      marginLeft: "10%",
                                      marginBottom: "2%",
                                    }}
                                  >
                                    {ticketObj.name}
                                    {"-"}Rs.
                                    {ticketObj.price -
                                      ticketObj.selectmini_discount}
                                  </li>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      )}
                      {activeTab === 3 && (
                        <div>
                          <p style={{ marginBottom: "2%" }}>
                            For existing Knocksense Platinum Members, tickets
                            will be available -
                          </p>

                          {_.isEmpty(tickets) ? (
                            <></>
                          ) : (
                            <>
                              {tickets[0]?.ticket.map((ticketObj) => (
                                <div>
                                  <li
                                    style={{
                                      marginLeft: "10%",
                                      marginBottom: "2%",
                                    }}
                                  >
                                    {ticketObj.name}
                                    {"-"}Rs.
                                    {ticketObj.price -
                                      ticketObj.platinum_discount}
                                  </li>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      )}
                    </h6>
                  </div>
                </div>

                <Footer
                  onBuyNowPressed={() => {
                    setBottomSheetOpen(true);
                    setopen(false);
                  }}
                  price={event.ticket ? event.ticket[0].ticket[0].price : 0}
                />
              </div>
            </BottomSheet>

            <BottomSheet
              onDismiss={() => setBottomSheetOpen(false)}
              open={bottomSheetOpen}
            >
              {showBookTicket ? (
                <BookTickets
                  ticket={selectedTicket}
                  eventDetails={event}
                  onSubmitPressed={onTicketInfoSubmit}
                  details={
                    typeof event.description === "string"
                      ? getDetails()
                      : event.description || []
                  }
                />
              ) : (
                <ChooseTicketType
                  eventDetails={event}
                  onTicketSelected={(ticket) => {
                    setSelectedTicket(ticket);
                  }}
                />
              )}
            </BottomSheet>

            <BottomSheet
              onDismiss={() => setCheckoutSheetOpen(false)}
              open={checkoutSheetOpen}
            >
              <Checkout
                eventDetails={event}
                selectedTicket={selectedTicket}
                ticketDetails={ticketDetails}
                proceedPaytmPayment={proceedPaytmPayment}
              />
            </BottomSheet>
          </div>
        )}
      </>
    );
  };
  return <HomePageComponent />;
}
export default Homepage;
